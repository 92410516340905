import {PropsWithChildren, useRef} from "react";
import {useInView} from "framer-motion";

type SectionInViewProps = {
    transform?: string
    transition?: string
}

export function SectionInView(props: PropsWithChildren<SectionInViewProps>) {

    const ref = useRef(null)
    const isInView = useInView(ref)

    return (
        <div ref={ref}
             style={{
                 transform: isInView ? "none" : props.transform === undefined ? "translateX(-200px)" : props.transform,
                 opacity: isInView ? 1 : 0,
                 transition: props.transition === undefined ? "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s" : props.transition
             }}
        >
            {props.children}
        </div>
    )
}