import {Box, Grid, IconButton, Typography} from "@mui/material";
import {Computer, FmdGood, GitHub, Group, Instagram, Mail} from "@mui/icons-material";
import React from "react";
import {SectionInView} from "../../gecsevar_framework/components/SectionInView";

export function MyFooter() {

    return <Grid marginTop={0} container direction="column" justifyContent="center" alignItems="center"
                 sx={{backgroundColor: "#2fadfa", backgroundImage: "linear-gradient(245deg, #2fadfa 0%, #3d407c 100%)"}}
    >
        <Grid container spacing={0}>
            <Grid item xs={12} sm={6} md={6}>
                <Grid container component={"span"} direction="column" justifyContent="center" alignItems="center"
                      marginTop={4} marginBottom={4}>
                    <Grid item>
                        <SectionInView>
                            <Box component={"img"} sx={{height: "auto", maxHeight: "50px", maxWidth: "100%",}}
                                 alt="LOGO"
                                 src="/images/_.png"
                            />
                            <Typography variant={"body1"} fontStyle={"italic"} marginTop={2} marginBottom={2}>
                                Follow me:
                            </Typography>
                        </SectionInView>
                    </Grid>
                    <Grid item>
                        <Box sx={{display: "flex"}}>
                            <SectionInView>
                                <IconButton color={"secondary"} onClick={() => window.open("https://www.instagram.com/bela")}><Instagram
                                    htmlColor={"white"} fontSize={"large"}/></IconButton>
                                <IconButton color={"secondary"} onClick={() => window.open("https://.com/")}><GitHub htmlColor={"white"}
                                                                                                                     fontSize={"large"}/></IconButton>
                                <IconButton color={"secondary"} onClick={() => window.open("https://.hu/")}><Computer htmlColor={"white"}
                                                                                                                      fontSize={"large"}/></IconButton>
                                <IconButton color={"secondary"} onClick={() => window.open("https://.hu/")}><Mail htmlColor={"white"}
                                                                                                                  fontSize={"large"}/></IconButton>
                                <IconButton color={"secondary"} onClick={() => window.open("https://.com/")}><Group htmlColor={"white"}
                                                                                                                    fontSize={"large"}/></IconButton>
                            </SectionInView>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <SectionInView transform={"translateX(200px)"}>
                    <Box sx={{display: "flex", flexDirection: "column", alignContent: "center"}}>
                        <Typography variant={"h5"} color={"white"} marginTop={8} marginBottom={0} align={"center"} fontWeight={"bold"}>
                            About
                        </Typography>
                        <Box marginTop={2} sx={{display: "flex", justifyContent: "center"}}>
                            <FmdGood color={"warning"}/>
                            <Typography marginLeft={1}>Hungary - Budapest</Typography>
                        </Box>
                        <Box marginTop={2} sx={{display: "flex", justifyContent: "center"}}>
                            <Mail color={"warning"}/>
                            <Typography marginLeft={1}>Send message</Typography>
                        </Box>
                    </Box>
                </SectionInView>
            </Grid>
        </Grid>
    </Grid>
}