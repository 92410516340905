import {GecsevarConfigTheme} from "../GecsevarConfigTheme";
import MenuBackground from "../../../assets/images/gecsevar.hu/natures-beauty-reflected-tranquil-mountain-waters-generative-ai.jpg";

export class GVHuConfigTheme implements GecsevarConfigTheme {

    getHeaderBarBackground(): string {
        return "transparent"
    }

    getHeaderBarBackdropFilter(): string {
        return "blur(30px)"
    }

    getHeaderLogo(size: string): string {
        if (size === "xs") {
            return "/images/_.png"
        } else if (size === "sm") {
            return "/images/_.png"
        }
        return ""
    }

    getMenuBackground(): string {
        return `linear-gradient(to top, #002147aa, #002147dd), url(${MenuBackground}) no-repeat top center`
    }

    getContainerBackground(): string {
        return ""
    }

    getContainerInnerGridBackground(): string {
        return ''   // default
    }

    getContainerInnerPaperBackground(): string {
        return ''   // default
    }

    getArticleListPageBackground(): string {
        return 'transparent'
    }
}