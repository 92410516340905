import {AxiosResponse} from "axios";
import {plainToInstance} from "class-transformer";
import {DrawerMenuModel} from "../../models/DrawerMenuModel";
import menuService from "../../services/MenuService";
import {MyDashboard} from "../../../pages/gecsevar.hu/MyDashboard";
import React from "react";
import {MyCopyright} from "../../../pages/gecsevar.hu/MyCopyright";
import {MyFooter} from "../../../pages/gecsevar.hu/MyFooter";
import {GecsevarConfig, HEADER_COMPONENT, HeaderButton, HTML_HEAD_META} from "../GecsevarConfig";
import {Box, Grid} from "@mui/material";
import {GVHuConfigTheme} from "./GVHuConfigTheme";

export class GVHuConfig extends GecsevarConfig {

    private initialized = false
    private menuMap = new Array<DrawerMenuModel>()
    private headerButtons = new Array<HeaderButton>()

    initialize(initCallback: () => void) {
        this.gvConfigTheme = new GVHuConfigTheme()
        menuService.get((resp: AxiosResponse) => {
            this.menuMap = resp.data.map((value: string) => {
                return plainToInstance(DrawerMenuModel, value)
            })
            this.initialized = true
            initCallback()
        })
    }

    getHtmlHead(meta: HTML_HEAD_META): string {
        let res = ""

        switch (meta) {
            case HTML_HEAD_META.META_TITLE: {
                res = "Gecsevar main Site - Software Development, Maintenance and Management"
                break
            }
            case HTML_HEAD_META.META_DESCRIPTION: {
                res = "Web and other software developments, articles, news and usefully code samples"
                break
            }
            case HTML_HEAD_META.META_NAME: {
                res = "name"
                break
            }
        }
        return res
    }

    isHeaderComponentVisible(item: HEADER_COMPONENT): boolean {
        let res = false
        switch (item) {
            case HEADER_COMPONENT.LOGO:
                res = true
                break;
            case HEADER_COMPONENT.SEARCH:
                res = true
                break;
            case HEADER_COMPONENT.COLOR_MODE:
                res = true
                break;

        }
        return res
    }

    getHeaderButtons(): HeaderButton[] {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        return this.headerButtons
    }

    getHeaderButtonElementBy(name: string): JSX.Element {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        switch (name) {
            case 'Uzenetkuldes' :
                return <div/>
            case 'Fogadoest'    :
                return <div/>
        }
        console.error('Missing Element for: ' + name)
        return <></>
    }

    getMenuTitle(): string {
        return "Content"
    }

    getContentSideBox(): JSX.Element {
        return <Grid item xs={0} md={4} lg={4} xl={4} sx={{display: {xs: 'none', md: 'block', lg: 'block', xl: 'block', xxl: 'block', x4l: 'block'}}}>
            <Box marginTop={4}>
            </Box>
        </Grid>
    }

    getMenuNavigation(): Array<DrawerMenuModel> {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        return this.menuMap.filter(value => {
            return value.level !== "submenu"
        })
    }

    getSubMenuFor(menuId: string): Array<DrawerMenuModel> {
        if (!this.initialized)
            console.error("Gecsevar Config Uninitialized!")
        let inZone = false;
        const temp: Array<DrawerMenuModel> = [];
        this.menuMap.map(value => {
            if (value.id === menuId) {
                inZone = true
            } else if (inZone && value.level === "submenu") {
                temp.push(value)
            } else if (inZone && value.level !== "submenu") {
                inZone = false
            }
            return null
        })

        return temp
    }

    getDefaultArticleListCategory(): string {
        return "article"
    }

    getDashboard(): JSX.Element {
        return <MyDashboard/>
    }

    getFooterContent(): JSX.Element {
        return <MyFooter/>
    }

    getCopyrightContent(): JSX.Element {
        return <MyCopyright/>
    }

    getItemsCountOnArticleListPage(): number {
        return 5
    }

}
