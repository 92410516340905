import {Grid} from "@mui/material";
import React from "react";
import {ArticleListPage, ArticleListPageType} from "../../gecsevar_framework/components/ArticleListPage";
import {gecsevarConfig} from "../../gecsevar_framework/pages/MainPage";
import {motion} from "framer-motion";
import {SectionInView} from "../../gecsevar_framework/components/SectionInView";

export function MyDashboard() {

    return <Grid container margin={0} spacing={0} direction={"row"} justifyContent={"center"} alignItems={"stretch"}
                 sx={{backgroundColor: "#2fadfa", backgroundImage: "linear-gradient(315deg, #2fadfa 0%, #3d407c 100%)"}}
    >
        <Grid item xs={12} md={6} lg={6}>
            <SectionInView transform={""} transition={""}>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    transition={{
                        duration: 1,
                        delay: 0
                    }}
                >
                    <ArticleListPage type={ArticleListPageType.dashboard} category={gecsevarConfig.getDefaultArticleListCategory()}/>
                </motion.div>
            </SectionInView>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
            <SectionInView transform={""} transition={""}>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    transition={{
                        duration: 1,
                        delay: 1
                    }}
                >
                    <ArticleListPage type={ArticleListPageType.dashboard} category={"Kotlin"}/>
                </motion.div>
            </SectionInView>
        </Grid>
    </Grid>
}
