import {Box, Grid, Link, Typography} from "@mui/material";
import React from "react";
import {SectionInView} from "../../gecsevar_framework/components/SectionInView";

export function MyCopyright() {
    return <Box sx={{
        flexShrink: 0, p: 1, border: 1, borderColor: 'grey.800', color: 'white', display: {xs: 'block', sm: 'flex'},
        backgroundColor: "#2d92d7", backgroundImage: "linear-gradient(90deg, #3d407c 0%, #2d92f7 50%, #3d407c 100%)"
    }}
                justifyContent={"space-between"} alignItems={"center"}
    >
        <Grid item>
            <SectionInView transform={"translateX(400px)"}>
                <Typography variant="body2" color="lightgrey" align="left">
                    {'Copyright © '}
                    {new Date().getFullYear()} Gecse Va'r | HU
                </Typography>
            </SectionInView>
        </Grid>
        <Grid item>
            <SectionInView transform={"translateX(-400px)"}>
                <Typography variant="body2" color="lightgrey" align="right">
                    {'Running on: '}
                    GecseVa'r React/JS | Kotlin/ktor framework
                    <Link color="inherit" href="https://www.gecsevar.hu">

                    </Link>
                </Typography>
            </SectionInView>
        </Grid>
    </Box>
}